/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Origami Depot</title>

        <meta
          name="description"
          content="Origami Depot is your top source for authentic japanese decorations & supplies."
        />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link rel="canonical" href="https://origamidepot.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Origami Depot" />
        <meta
          property="og:description"
          content="Origami Depot is your top source for authentic japanese decorations & supplies."
        />
        <meta property="og:url" content="https://origamidepot.com/" />
        <meta property="og:site_name" content="Origami Depot" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Origami Depot is your top source for authentic japanese decorations & supplies."
        />
        <meta name="twitter:title" content="Origami Depot" />

        <link
          rel="icon"
          type="image/png"
          href="https://imgix.cosmicjs.com/0af24790-5363-11eb-bbc3-d5edc25fc7c1-favicon.png"
        />
        <script>fbq('track', 'ViewContent');</script>
      </Helmet>
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
